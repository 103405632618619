import { Directive } from '@angular/core';
import { Injectable } from '@angular/core';
import { ExternalApp } from '../core/model/external-app'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ExternalAppService {
private API_URL= environment.api_url;

  constructor(private httpClient: HttpClient) { }

  public loadExternalApps() : Observable<ExternalApp[]> {
        return this.httpClient.get<ExternalApp[]>(this.API_URL + '/apps');
  }
}