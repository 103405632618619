import { Injectable } from '@angular/core';
import { ClientCompany } from '../core/model/client-company'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

private API_URL= environment.api_url;

	protected companies: ClientCompany[] = [];

	constructor(private httpClient: HttpClient) {	
	 }

  public loadCompanies() : Observable<ClientCompany[]> {
        return this.httpClient.get<ClientCompany[]>(this.API_URL + '/companies');
  }
}
