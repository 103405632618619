import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyAppsComponent } from './my-apps/my-apps.component';
import { MeComponent } from './me/me.component';
import { WeatherComponent } from './_components/weather/weather.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/me', pathMatch: 'full' },
  { path: 'me', component: MeComponent },
  { path: 'apps', component: MyAppsComponent, canActivate:[AuthGuard] },
  { path: 'weather', component: WeatherComponent },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
