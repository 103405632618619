import { Component, OnInit } from '@angular/core';
import {CompanyService} from './company.service';
import {ClientCompany} from '../core/model/client-company';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.sass']
})
export class CompanyComponent implements OnInit {

  private API_URL= environment.api_url;
	public companies: ClientCompany[];
	
	constructor(private companyService: CompanyService) { }

	ngOnInit() {
		this.companyService.loadCompanies().subscribe((data)=>{
      this.companies = data;
      for (var i = this.companies.length - 1; i >= 0; i--) {
        this.companies[i].logo.url = this.API_URL + this.companies[i].logo.url;
      }
      this.companies = this.companies.sort((a, b) => Number(b.id) - Number(a.id));
    });
	}

}
