import { Injectable } from '@angular/core';
import { Person } from '../core/model/person';
import { ClientCompany } from '../core/model/client-company';

@Injectable({
  providedIn: 'root'
})
export class PersonService {
	private me: Person;
  private tamper: Person;

  	constructor() { 
  		this.me = new Person(
  			'1',
  			'Nassib Bou Malham',
        '/assets/me.png',
  			new Date("05/09/1986"),
  			new URL('https://www.dropbox.com/s/9qb2pllcvk3qcy8/nbm-resume.pdf?dl=0'),
  			'candidature@boumalham.com',
  			['manager','architect', 'creator', 'developer', 'cyclist','builder'],
  			'Project Manager',
  			new ClientCompany('99', 'Wavatec', null, 'Wavatec', new URL('https://www.wavatec.fr'))
  			);
       this.tamper = new Person(
        '2',
        'This website is stolen',
        '/assets/warning.png',
        new Date("01/01/1900"),
        new URL('https://hadopi.fr/'),
        'thief@boumalham.com',
        [''],
        '',
        new ClientCompany('100', window.location.href + ' stole this content', null, '', new URL('https://www.cnil.fr/'))
        );
   	}

  public loadPerson(index) {
  	 return (index == 1 ? this.me : this.tamper);
  }
}
