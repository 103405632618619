import { Media } from './media'

export class ClientCompany {
		constructor(
		public id:string,
		public name:string,
		public logo: Media,
		public description:string,
		public link: URL
		) {
	}
}
