import { Directive } from '@angular/core';
import { Injectable } from '@angular/core';
import { Tempo } from '../core/model/tempo'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TempoService {
private API_URL= environment.api_url;

  constructor(private httpClient: HttpClient) { }

  public loadHomeTempo() : Observable<Tempo> {
        return this.httpClient.get<Tempo>(this.API_URL + '/sonde');
  }
}