import { Directive } from '@angular/core';
import { Injectable } from '@angular/core';
import { Weather } from '../core/model/weather'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class WeatherService {
private API_URL= environment.api_url;

  constructor(private httpClient: HttpClient) { }

  public loadWeather() : Observable<Weather> {
        return this.httpClient.get<Weather>(this.API_URL + '/weather');
  }
}