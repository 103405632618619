import { Component, OnInit} from '@angular/core';
import {WeatherService} from '../../_services/weather.service';
import {TempoService} from '../../_services/tempo.service';
import {Weather} from '../../core/model/weather';
import {Tempo} from '../../core/model/tempo';

@Component({
  selector: 'weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.sass']
})
export class WeatherComponent implements OnInit{


  public tempo: Tempo;
  public weather: Weather;


  constructor(
    private weatherService: WeatherService, private tempoService: TempoService) {
  }

  ngOnInit() {
    this.tempoService.loadHomeTempo().subscribe((data)=>{
      this.tempo = data;
    });
    this.weatherService.loadWeather().subscribe((data)=>{
      this.weather = data;
    });
  }
}