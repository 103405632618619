import { Component, OnInit} from '@angular/core';
import {ExternalAppService} from './external-app.service';
import {ExternalApp} from '../core/model/external-app';

@Component({
  selector: 'my-apps',
  templateUrl: './my-apps.component.html',
  styleUrls: ['./my-apps.component.sass']
})
export class MyAppsComponent implements OnInit{


  public externalApps: ExternalApp[];
  public breakpoint;
  public innerWidthd;


  constructor(
    private externalAppService: ExternalAppService) {
  }

  ngOnInit() {
    this.externalAppService.loadExternalApps().subscribe((data)=>{
      this.externalApps = data;
    });
    this.breakpoint = (window.innerWidth <= 2000) ? ((window.innerWidth <= 1500) ? ((window.innerWidth <= 800) ? 2 : 4 ) : 8 ) : 10;
  }

  onResize(event) {
  this.breakpoint = (event.target.innerWidth <= 2000) ? ((event.target.innerWidth <= 1500) ? ((event.target.innerWidth <= 800) ? 2 : 4 ) : 8 ) : 10;
}

}
