import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyNavComponent } from './my-nav/my-nav.component';
import { MyAppsComponent } from './my-apps/my-apps.component';
import { MeComponent } from './me/me.component';
import { CompanyComponent } from './company/company.component';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './_components/alert.component';
import { HeaderComponent } from './_components/header/header.component';
import { WeatherComponent } from './_components/weather/weather.component';

import { GridsterModule } from 'angular-gridster2';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LayoutModule } from '@angular/cdk/layout';
import {MatGridListModule} from '@angular/material/grid-list'; 


import { PersonService } from './me/person.service';
import { CompanyService } from './company/company.service';
import { ExternalAppService } from './my-apps/external-app.service';
import { AuthenticationService } from './_services/authentication.service';
import { WeatherService } from './_services/weather.service';
import { TempoService } from './_services/tempo.service';

import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor'

@NgModule({
  declarations: [
    AppComponent,
    MyNavComponent,
    MyAppsComponent,
    MeComponent,
    CompanyComponent,
    LoginComponent,
    AlertComponent,
    HeaderComponent,
    WeatherComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    LayoutModule,
    MatSidenavModule,
    MatListModule,
    ReactiveFormsModule,
    HttpClientModule,
    GridsterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
