import {
  trigger,
  animate,
  transition,
  style,
  query
} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [



        transition( '* => *', [

            query(':enter', 
                [
                    style({ top: '-40px', position: 'relative' })
                ], 
                { optional: true }
            ),

            query(':enter', 
                [
                    style({ top: '-40px', position: 'relative' }),
                    animate('0.2s', style({ top: 0, position: 'relative' }))
                ], 
                { optional: true }
            )

        ])




]);
