import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../core/model/user'

import { AuthenticationService } from '../../_services/authentication.service'


@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.sass']
})

export class HeaderComponent {

    currentUser: User;

    constructor(private router: Router, private authenticationService : AuthenticationService) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}