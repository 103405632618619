import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import {PersonService} from './person.service';
import {Person} from '../core/model/person'

@Component({
  selector: 'me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.sass']
})
export class MeComponent implements OnInit{


  subscription: Subscription;
  public hobby= '';
  private index = 0;
  public age: number;
  public me: Person;

  constructor(
    private router: Router, private personService: PersonService) {
    let currentUrl = window.location.href
      if(!currentUrl.includes('boumalham.com') && !currentUrl.includes('localhost:4200') ) {
        setTimeout(() => {
          window.location.href = 'https://www.youtube.com/watch?v=D7Eydw648Z4&feature=youtu.be&t=61';
        }, 10000);  //5s
      }
  }

   ngOnInit(): void {
      let currentUrl = window.location.href
      if(!currentUrl.includes('boumalham.com') && !currentUrl.includes('localhost:4200') ) {
        this.me = this.personService.loadPerson(2);    
      }
      else {
        this.me = this.personService.loadPerson(1);    
      }
      this.calculateAge();
      this.updateHobby();

      const source = interval(1500);
      const text = 'Your Text Here';
      this.subscription = source.subscribe(val => (this.updateHobby()));
  }

ngOnDestroy() {
  this.subscription.unsubscribe();
}
  updateHobby() {
    var j = (this.index) % (this.me.hobbies.length); // (i + 1) to start at second word
    //so the j rotates like: 1, 2, 3, 4, 0, 1, 2,...
    this.hobby = this.me.hobbies[j]; //changes text inside span
    this.index++;
    this.index = (this.index > 100 ? 0 : this.index);
}

  calculateAge(): void {
    if (this.me.dob) {
    var timeDiff = Math.abs(Date.now() - this.me.dob.getTime());
    this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    }
    else this.age = 99;
}

}