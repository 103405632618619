import { Component } from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import { fadeAnimation } from './animations/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  animations: [fadeAnimation]
})
export class AppComponent {
  public title = 'Nassib Bou Malham';
  public copywriteDate;

    constructor() {
      this.copywriteDate = (new Date()).getFullYear();
  }

}
