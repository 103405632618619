import { ClientCompany } from './client-company'

export class Person {
	constructor(
		public id:string,
		public name:string,
		public picture:string,
		public dob:Date,
		public resumeLink: URL,
		public mail: string,
		public hobbies: string[],
		public position:string,
		public company: ClientCompany
		) {
	}
}
